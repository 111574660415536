import React, {useEffect, useState} from "react";

import axios from "axios";
import dateFormat, { masks } from "dateformat";

import { useLocation } from "react-router-dom";

function Detail(props) {
    const [item, setItem] = useState({});

    const location= useLocation();

    useEffect(() => {
        setItem(location.state.item);
    }, []);

    var salaryKind = {
        year: "연봉",
        month: "월급",
        hour: "시급"
    }

    const convertKoreanDayOfWeek = (dayOfWeek) => {
        switch(dayOfWeek) {
            case "MON": return "월 "
            case "TUE": return "화 "
            case "WED": return "수 "
            case "THU": return "목 "
            case "FRI": return "금 "
            case "SAT": return "토 "
            case "SUN": return "일 "
            default: return ""
        }
    }

    return (
        <div className="App">
            <div className="wrapper">
                <div className="content-wrapper" style={{marginLeft: '0px'}}>

                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/page/biz/contract/list">근로계약서 목록</a></li>
                                        <li className="breadcrumb-item active">상세보기</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="card-body">
                                    {/*<form>*/}
                                    {/*    <div className="row">*/}
                                    {/*        <div className="col-sm-6">*/}
                                    {/*            <div className="form-group">*/}
                                    {/*                <label>사업장명</label>*/}
                                    {/*                <input type="text" className="form-control" placeholder="Enter ..."*/}
                                    {/*                       disabled value={bizContract.bizName}/>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="col-sm-6">*/}
                                    {/*            <div className="form-group">*/}
                                    {/*                <label>사업자 번호</label>*/}
                                    {/*                <input type="text" className="form-control" placeholder="Enter ..."*/}
                                    {/*                       disabled value={bizContract.bizNo}/>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</form>*/}
                                    <div className="row"></div>
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <tbody>
                                                    <tr>
                                                        <th>계약서 별칭</th>
                                                        <td>{item.alias}</td>
                                                        <th>대표자이름</th>
                                                        <td colSpan={3}>{item.ceoName}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{width: '150px'}}>상호명</th>
                                                        <td>{item.bizName}</td>
                                                        <th style={{width: '150px'}}>사업자번호</th>
                                                        <td>{item.bizNo}</td>
                                                        <th>사업장 주소</th>
                                                        <td>{item.bizAddress}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>근로자명</th>
                                                        <td>{item.workerName}</td>
                                                        <th>근로자 생년월일</th>
                                                        <td>{item.workerBirthday}</td>
                                                        <th>근로자 연락처</th>
                                                        <td>{item.workerPhoneNumber}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>근로자 주소</th>
                                                        <td>{item.workerAddress}</td>
                                                        <th>근무지 주소</th>
                                                        <td>{item.workAddress}</td>
                                                        <th>근무 시작일</th>
                                                        <td>{item.workStartDate}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>담당 업무</th>
                                                        <td>{item.workResponsibility}</td>
                                                        <th>근무 시작시간</th>
                                                        <td>{item.workStartTime}</td>
                                                        <th>근무 종료시간</th>
                                                        <td>{item.workEndTime}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>근무 휴게 시간</th>
                                                        <td>{item.workRestMinutes && item.workRestMinutes + ' 분'}</td>
                                                        <th>근무 요일</th>
                                                        <td>
                                                            {
                                                                (item.weekWorkDays && item.weekWorkDays.map(weekWorkDay => {
                                                                    return convertKoreanDayOfWeek(weekWorkDay.weekDayType)
                                                                }))
                                                            }
                                                        </td>
                                                        <th>유급 휴일</th>
                                                        <td>
                                                            {
                                                                (item.weekWorkPaidOffDays && item.weekWorkPaidOffDays.map(weekWorkDay => {
                                                                    return convertKoreanDayOfWeek(weekWorkDay.weekDayType)
                                                                }))
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>급여 형태</th>
                                                        <td>
                                                            {
                                                                (item.salaryKind && salaryKind[item.salaryKind.toLowerCase()])
                                                            }
                                                        </td>
                                                        <th>급여</th>
                                                        <td>
                                                            {
                                                                (item.salaryAmount && new Intl.NumberFormat('ko-KR', {
                                                                    style: 'currency',
                                                                    currency: 'krw'
                                                                }).format(item.salaryAmount))
                                                            }
                                                        </td>
                                                        <th>급여일</th>
                                                        <td>
                                                            {item.payday && item.payday + ' 일'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>계약일</th>
                                                        <td>{item.contractDate}</td>
                                                        <th>특약</th>
                                                        <td>{item.specialContract}</td>
                                                        <th>파일 공유일</th>
                                                        <td>
                                                            {
                                                                (
                                                                    item.shares && item.shares.map(share => {
                                                                        return (
                                                                            <p>{dateFormat(share.createdAt, 'yyyy-mm-dd HH:mm')}</p>
                                                                        )
                                                                    })
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>기타수당</th>
                                                        <td colSpan={5}>
                                                            {
                                                                (item.salaryAllowances && item.salaryAllowances.map(allowance => {
                                                                    return (
                                                                        <span>{allowance.title} : {new Intl.NumberFormat('ko-KR', {
                                                                            style: 'currency',
                                                                            currency: 'krw'
                                                                        }).format(allowance.amount)}, </span>
                                                                    )
                                                                }))
                                                            }
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                </div>

                                {/* /.card-body */}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default Detail;
