import React from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';

const axiosInstance = Axios.create({
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
    },
});



axiosInstance.interceptors.request.use(req => {
    // 로그 남기기
    console.log(`${JSON.stringify(req, null, 10000000)}`);
    return req;
});

axiosInstance.interceptors.response.use(
    response => {
        console.log(response.data);
        return response;
    },
    (error) => {
        // const navigator = useNavigate();
        console.log(error);
        if (error.response.status === 403) {
            // navigator('/')
            window.location.href = "/";
        }
        // return Promise.reject(error);
    }
);

export default axiosInstance;