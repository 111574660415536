import React, {useEffect, useState} from "react";

import axios from "config/axiosConfig";
import dateFormat, { masks } from "dateformat";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Pagination} from "page/util/Pagination";

function List() {
    const [pageNo, setPageNo] = useState(1);
    const [pagination, setPagination] = useState({});
    const [contracts, setContracts] = useState([]);

    const location= useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`/admin/biz/contracts?page=${pageNo}`)
            .then((res) => {
                setContracts(res.data.content);
                setPagination({
                    pageNo: res.data.pageNo,
                    startPageNo: res.data.startPageNo,
                    endPageNo: res.data.endPageNo,
                    hasPrevious: res.data.hasPrevious,
                    hasNext: res.data.hasNext,
                });
            });
    }, [pageNo]);

    const navigateToDetail = (id) => {
        axios.get('/admin/biz/contracts/' + id)
            .then((res) => {
                navigate("/page/biz/contract/detail", {state: {item: res.data}});
            });
    };

    const pdfDownload = (id) => {
        window.open(`/admin/biz/contracts/${id}/download`, '_blank');
    }

    return (
        <div className="App">
            <div className="wrapper">
                <div className="content-wrapper" style={{marginLeft: '0px'}}>
                    <div className="content-header">
                    <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item active">근로계약서 목록</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        {/*<div className="card-header">*/}
                                        {/*    <div className="card-tools">*/}
                                        {/*        <div className="input-group input-group-sm" style={{width: '500px'}}>*/}
                                        {/*            <input className="form-control float-right" name="table_search"*/}
                                        {/*                   placeholder="Search" type="text"/>*/}
                                        {/*            <div className="input-group-append">*/}
                                        {/*                <button className="btn btn-default" type="submit">*/}
                                        {/*                    <i className="fas fa-search"/>*/}
                                        {/*                </button>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div className="card-body table-responsive p-0">
                                            <table className="table table-hover text-nowrap">
                                                <thead>
                                                <tr>
                                                    <th>Seq</th>
                                                    <th>사업장명</th>
                                                    <th>사업자번호</th>
                                                    <th>계약서별칭</th>
                                                    <th>생성일</th>
                                                    <th>상세보기</th>
                                                    <th>다운로드</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    contracts.map(item => {
                                                        const createdAt = dateFormat(item.createdAt, 'yyyy-mm-dd HH:mm');
                                                        return (
                                                            <tr key={item.id}>
                                                                <td>{item.id}</td>
                                                                <td>{item.bizName}</td>
                                                                <td>{item.bizNo}</td>
                                                                <td>{item.alias}</td>
                                                                <td>{createdAt}</td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-block btn-outline-primary btn-sm"
                                                                        style={{width: '70px'}}
                                                                        onClick={() => navigateToDetail(item.id)}>
                                                                        보기
                                                                    </button>
                                                                </td>
                                                                <td>
                                                                    <button type="button"
                                                                            className="btn btn-block btn-outline-primary btn-sm"
                                                                            style={{width: '70px'}}
                                                                            onClick={() => pdfDownload(item.id)}>
                                                                        다운로드
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Pagination
                            pageNo={pagination.pageNo}
                            startPageNo={pagination.startPageNo}
                            endPageNo={pagination.endPageNo}
                            hasPrevious={pagination.hasPrevious}
                            hasNext={pagination.hasNext}
                            setPageNo={setPageNo}
                        />
                    </section>
                </div>
            </div>
        </div>
    );
}

export default List;
