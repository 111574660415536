import React from "react";

interface Pager {
    pageNo: number,
    startPageNo: number,
    endPageNo: number,
    hasPrevious: boolean,
    hasNext: boolean,
}

export function Pagination({pageNo, startPageNo, endPageNo, hasPrevious, hasNext, setPageNo}: Pager) {
    const items = [];
    if (hasPrevious) {
        items.push(
            <li className="page-item">
                <a className="page-link" href="#" onClick={() => setPageNo(startPageNo - 1)}>&lt;</a>
            </li>
        )
    }

    for (let i = startPageNo; i <= endPageNo; i++) {
        items.push(
            <li className={"page-item " + (pageNo === i ? 'active' : '')}>
                <a className="page-link" href="#" onClick={() => setPageNo(i)}>{i}</a>
            </li>
        )
    }

    if (hasNext) {
        items.push(
            <li className="page-item">
                <a className="page-link" href="#" onClick={() => setPageNo(endPageNo + 1)}>&gt;</a>
            </li>
        )
    }
    return (
        <div style={{paddingTop: '10px'}}>
            <nav aria-label="Contacts Page Navigation">
                <ul className="pagination justify-content-center m-0">
                    {items}
                </ul>
            </nav>
        </div>
    )
}