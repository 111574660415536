import React, {useEffect, useState} from "react";

import axios from "config/axiosConfig";
import dateFormat from "dateformat";
import {useLocation, useNavigate} from "react-router-dom";
import {Pagination} from "page/util/Pagination";

const nowYear = () => {
    const now = new Date();
    return now.getFullYear();
}

function List() {
    const [pageNo, setPageNo] = useState(1);
    const [year, setYear] = useState(nowYear);
    const [halfYear, setHalfYear] = useState('FIRST');
    const [membership, setMembership] = useState('BUSINESS');
    const [years, setYears] = useState([2024]);

    const [pagination, setPagination] = useState({});
    const [results, setResults] = useState([]);

    let initYears = [];
    for (let i = 2024; i <= nowYear(); i++) {
        initYears.push(i);
    }
    initYears = initYears.reverse();

    const date = new Date();
    let month = date.getMonth() + 1;

    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search); // 쿼리 파라미터 추출
    const paramYear = queryParams.get("year");
    const paramHalfYear = queryParams.get("halfYear");
    const paramPageNo = queryParams.get("pageNo");
    const paramMembership = queryParams.get("membership");

    debugger;

    const search = (paramPageNo) => {
        if (paramPageNo !== null) {
            setPageNo(paramPageNo);
        }
        const page = paramPageNo ?? pageNo;
        const uri = '/admin/biz/expected-vats?pageNo=' + page + '&year=' + year + '&halfYearType=' + halfYear + '&membership=' + membership;
        getList(uri);
    };

    const getList = (uri) => {
        axios.get(uri)
            .then((res) => {
                setResults(res.data.content);
                setPagination({
                    pageNo: res.data.pageNo,
                    startPageNo: res.data.startPageNo,
                    endPageNo: res.data.endPageNo,
                    hasPrevious: res.data.hasPrevious,
                    hasNext: res.data.hasNext,
                });
            });
    };

    const deleteVat = (id) => {
        axios.delete(`/admin/biz/expected-vats/${id}`)
            .then((res) => {
                search(null);
            });
    };

    const navigateToEdit = (bizNo, bizName, vatId) => {
        let uri = "/page/biz/expected/edit?bizNo=" + bizNo + "&bizName=" + bizName + "&year=" + year + "&halfYear=" + halfYear + "&membership=" + membership + "&pageNo=" + pageNo;
        if (vatId !== null) {
            uri = uri.concat(`&vatId=${vatId}`)
        }

        navigate(uri);
    };

    const renderPagination = () => {
        const items = [];
        if (pagination.hasPrevious) {
            items.push(
                <li className="page-item">
                    <a className="page-link" href="#" onClick={() => search(pagination.startPageNo - 1)}>&lt;</a>
                </li>
            )
        }

        for (let i = pagination.startPageNo; i <= pagination.endPageNo; i++) {
            items.push(
                <li className={"page-item " + (pagination.pageNo === i ? 'active' : '')}>
                    <a className="page-link" href="#" onClick={() => search(i)}>{i}</a>
                </li>
            )
        }

        if (pagination.hasNext) {
            items.push(
                <li className="page-item">
                    <a className="page-link" href="#" onClick={() => search(pagination.endPageNo + 1)}>&gt;</a>
                </li>
            )
        }

        return (
            <div style={{paddingTop: '10px'}}>
                <nav aria-label="Contacts Page Navigation">
                    <ul className="pagination justify-content-center m-0">
                        {items}
                    </ul>
                </nav>
            </div>
        )
    }

    useEffect(() => {
        const initPageNo = paramPageNo ?? 1;
        const initYear = paramYear ?? initYears.at(0);
        const initHalfYear = paramHalfYear ?? (month >= 1 && month <= 6) ? 'FIRST' : 'SECOND';
        const initMembership = paramMembership ?? membership;

        setPageNo(initPageNo);
        setYears(initYears);
        setHalfYear(initHalfYear);
        setMembership(initMembership)

        const uri = `/admin/biz/expected-vats?pageNo=${initPageNo}&year=${initYear}&halfYearType=${initHalfYear}&membership=${initMembership}`;
        getList(uri);
    }, []);

    return (
        <div className="App">
            <div className="wrapper">
                <div className="content-wrapper" style={{marginLeft: '0px'}}>
                    <div className="content-header">
                    <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item active">예상부가세 보고서 목록</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-tools" style={{marginTop: '40px'}}>
                                            <button type="submit" className="btn btn-info float-right" style={{width: '80px', marginRight: '10px'}} onClick={ () => search(1)}>검색</button>

                                            <select className="form-control float-right"
                                                    style={{width: '200px', marginRight: '10px'}}
                                                    onChange={ (e) => setMembership(e.target.value)}
                                                    value={membership}>
                                                <option value="BUSINESS">BUSINESS</option>
                                                <option value="BASIC">BASIC</option>
                                                <option value="">ALL</option>
                                            </select>
                                            <select className="form-control float-right" style={{width: '200px', marginRight: '10px'}}
                                                    onChange={ (e) => setHalfYear(e.target.value) }
                                                    value={halfYear}>
                                                <option value="FIRST">1 기</option>
                                                <option value="SECOND">2 기</option>
                                            </select>
                                            <select className="form-control float-right" style={{width: '200px', marginRight: '10px'}}
                                                    onChange={ (e) => setYear(e.target.value)}
                                                    value={year}>
                                                {
                                                    years.map(year => {
                                                        return (
                                                            <option value={year} key={year}>{year}년</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>

                                        <div className="card-body table-responsive p-0" style={{marginTop: '20px'}}>
                                            <table className="table table-hover text-nowrap" style={{textAlign: "center"}}>
                                                <thead>
                                                <tr>
                                                    <th>bizId</th>
                                                    <th>사업자명</th>
                                                    <th>사업자번호</th>
                                                    <th>멤버십</th>
                                                    <th>보고서</th>
                                                    <th>생성일</th>
                                                    <th>노출여부</th>
                                                    <th>삭제</th>
                                                    <th>수정</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    results.map(item => {
                                                        return item.expectedVatCreatedAt === null ?
                                                            (
                                                                <tr key={item.bizId}>
                                                                    <td>{item.bizId}</td>
                                                                    <td>{item.bizName}</td>
                                                                    <td>{item.bizNo}</td>
                                                                    <td>{item.bizMembershipType}</td>
                                                                    <td colSpan={4}
                                                                        style={{textAlign: "center", color: "red"}}>보고서
                                                                        미생성
                                                                    </td>
                                                                    <td>
                                                                        <button type="button"
                                                                                className="btn btn-outline-primary btn-sm"
                                                                                style={{width: '70px'}}
                                                                                onClick={() => navigateToEdit(item.bizNo, item.bizName, null)}>
                                                                            생성
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                            :
                                                            (
                                                                <tr key={item.bizId}>
                                                                    <td>{item.bizId}</td>
                                                                    <td>{item.bizName}</td>
                                                                    <td>{item.bizNo}</td>
                                                                    <td>{item.bizMembershipType}</td>
                                                                    <td>{item.expectedVatYear}년 {item.expectedVatHalfYearType === 'FIRST' ? 1 : 2}기
                                                                        확정 예상 부가세 보고서
                                                                    </td>
                                                                    <td>{dateFormat(item.expectedVatCreatedAt, 'yyyy-mm-dd HH:mm')}</td>
                                                                    <td>{item.expectedVatVisible ? 'O' : 'X'}</td>
                                                                    <td>
                                                                        <button
                                                                            className="btn btn-outline-primary btn-sm"
                                                                            style={{width: '70px'}}
                                                                            onClick={() => deleteVat(item.vatId)}>
                                                                            삭제
                                                                        </button>
                                                                    </td>
                                                                    <td>
                                                                        <button type="button"
                                                                                className="btn btn-outline-primary btn-sm"
                                                                                style={{width: '70px'}}
                                                                                onClick={() => navigateToEdit(item.bizNo, item.bizName, item.vatId)}>
                                                                            수정
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Pagination
                            pageNo={pagination.pageNo}
                            startPageNo={pagination.startPageNo}
                            endPageNo={pagination.endPageNo}
                            hasPrevious={pagination.hasPrevious}
                            hasNext={pagination.hasNext}
                            setPageNo={setPageNo}
                        />

                    </section>
                </div>
            </div>
        </div>
    );
}

export default List;
