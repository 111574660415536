export default class PushTargetModel {
    constructor(bizId, bizNo, bizName, membership, userId, userName, fcmToken) {
        this.bizId = bizId;
        this.bizNo = bizNo;
        this.bizName = bizName;
        this.membership = membership;
        this.userId = userId;
        this.userName = userName;
        this.fcmToken = fcmToken;
    }
}