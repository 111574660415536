import React, {useEffect, useState} from "react";

import axios from "config/axiosConfig";
import PushTargetModel from "./PushTargetModel"
import {Pagination} from "../util/Pagination";


export default function Main() {
    const [pageNo, setPageNo] = useState(1);
    const [pagination, setPagination] = useState({});

    const [pushTitle, setPushTitle] = useState('');
    const [pushContent, setPushContent] = useState('');

    const [pushTargetBizUsers, setPushTargetBizUsers] = useState([{}]);
    const [resBizUsers, setResBizUsers] = useState([]);

    const [searchType, setSearchType] = useState('bizName');
    const [searchText, setSearchText] = useState('');

    const inputSearchTextActiveEnter = (e) => {
        if (e.key === "Enter") {
            handleSearchReq();
        }
    }

    const handleSearchReq = (e) => {
        const params = {};
        params.pageNo = pageNo;
        params.pageSize = 15;
        if (searchType === 'bizName') {
            params.bizName = searchText
        }
        if (searchType === 'userName') {
            params.userName = searchText
        }

        axios.get(`/admin/biz/users`, { params: params })
            .then((res) => {

                setPagination({
                    pageNo: res.data.pageNo,
                    startPageNo: res.data.startPageNo,
                    endPageNo: res.data.endPageNo,
                    hasPrevious: res.data.hasPrevious,
                    hasNext: res.data.hasNext,
                });
                
                const bizUsers = res.data.content
                    .map(function (item) {
                        return new PushTargetModel(
                            item.bizId,
                            item.bizNo,
                            item.bizName,
                            item.membership,
                            item.userId,
                            item.userName,
                            item.fcmToken ?? '',
                        )
                    }
                );
                setResBizUsers(bizUsers);
            });
    }

    const handleAppendPushTarget = (e, index) => {
        const bizUser = resBizUsers.at(index);
        const appendedBizUser = pushTargetBizUsers.find((item) => {
            return item.bizId === bizUser.bizId && item.userId === bizUser.userId;
        });

        if (appendedBizUser === undefined) {
            setPushTargetBizUsers([...pushTargetBizUsers, bizUser]);
        }
    }

    const handleRemovePushTarget = (e, index) => {
        const newArr = pushTargetBizUsers
            .filter((item, i) => {
                return i !== index
            });

        setPushTargetBizUsers(newArr);
    }

    const sendPush = (e) => {
        const ids = pushTargetBizUsers.map((item) => {
                return {bizId: item.bizId, userId: item.userId}
            })
            .filter((item) => { return item.userId !== undefined })

        let params = {};
        params.title = pushTitle;
        params.content = pushContent;
        params.ids = ids;

        if(params.ids.length === 0) {
            alert('대상자를 추가하세요.');
            return;
        }

        if(params.title === '' && params.content === '') {
            alert('제목과 내용을 확인하세요.');
            return;
        }

        axios.post(`/admin/push`, JSON.stringify(params))
            .then((res) => {
                alert('푸시 메시지가 전송되었습니다.');
                setPushTargetBizUsers([{}]);
            });
    }

    useEffect(() => {
        handleSearchReq();
    }, [pageNo]);

    return (
        <div className="App">
            <div className="wrapper">
                <div className="content-wrapper" style={{marginLeft: '0px'}}>
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item active">푸시 보내기</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="form-group row">
                                                <label htmlFor="pushTitle"
                                                       className="col-sm-2 col-form-label">제목</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control" id="pushTitle"
                                                           onChange={e => {setPushTitle(e.target.value)}}
                                                           value={pushTitle}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="pushContent"
                                                       className="col-sm-2 col-form-label">내용</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control" id="pushContent"
                                                           onChange={e => {setPushContent(e.target.value)}}
                                                           value={pushContent}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="pushTitle"
                                                       className="col-sm-2 col-form-label">대상</label>
                                                <div className="col-sm-10">
                                                    {
                                                        pushTargetBizUsers.map((item, index) => {
                                                            return (
                                                                <div style={{marginTop: '5px'}}>{item.bizNo} ::: {item.bizName} ::: {item.userName} :::
                                                                    {
                                                                        item.bizName !== undefined ?
                                                                            (
                                                                                <button
                                                                                    className="btn btn-outline-danger btn-sm"
                                                                                    style={{width: '50px', height: '30px', marginLeft: '10px'}}
                                                                                    onClick={(e) => handleRemovePushTarget(e, index)}>
                                                                                    삭제
                                                                                </button>
                                                                            )
                                                                            :
                                                                            (
                                                                                <span></span>
                                                                            )
                                                                    }

                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div style={{margin: "0 auto"}}>
                                                    {/*<button type="button" className="btn btn-outline-secondary">*/}
                                                    {/*    목록*/}
                                                    {/*</button>*/}
                                                    <button type="button" className="btn btn-outline-success" onClick={sendPush}>
                                                        전송
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card" style={{marginTop: "70px"}}>
                                        <div className="card-header">
                                            <div className="input-group row">
                                                <label className="col-sm-2 col-form-label">대상 찾기</label>

                                                <div className="input-group-prepend">
                                                    <button type="button"
                                                            className="btn btn-default dropdown-toggle"
                                                            data-toggle="dropdown" aria-expanded="false">
                                                        {
                                                            searchType === 'bizName' ?
                                                                (<span>사업장명</span>)
                                                                :
                                                                (<span>사용자 이름</span>)
                                                        }

                                                    </button>
                                                    <div className="dropdown-menu" style={{}}>
                                                        <a className="dropdown-item" href="#" onClick={ () => setSearchType('bizName')}>사업장명</a>
                                                        <a className="dropdown-item" href="#" onClick={ () => setSearchType('userName')}>사용자 이름</a>
                                                        <div className="dropdown-divider"/>
                                                    </div>
                                                </div>

                                                <input className="form-control float-right" name="searchText"
                                                       placeholder="검색" type="text"
                                                       onChange={(e) => setSearchText(e.target.value)}
                                                       onKeyDown={inputSearchTextActiveEnter}
                                                       value={searchText}/>
                                                <div className="input-group-append">
                                                    <button className="btn btn-default" type="submit" onClick={handleSearchReq}>
                                                        <i className="fas fa-search"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="card-body table-responsive p-0">
                                            <table className="table table-hover text-nowrap" style={{textAlign: "center"}}>
                                                <thead>
                                                <tr>
                                                    <th>biz id</th>
                                                    <th>사업자번호</th>
                                                    <th>사업장명</th>
                                                    <th>멤버십</th>
                                                    <th>사용자이름</th>
                                                    <th>푸시토큰</th>
                                                    <th>추가</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    resBizUsers.map((item, index) => {
                                                        return (
                                                            <tr key={`${item.bizId}-${item.userId}`}>
                                                                <td>{item.bizId}</td>
                                                                <td>{item.bizNo}</td>
                                                                <td>{item.bizName}</td>
                                                                <td>{item.membership}</td>
                                                                <td>{item.userName}</td>
                                                                <td>{item.fcmToken === '' ? 'X' : 'O'}</td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-outline-primary btn-sm"
                                                                        style={{width: '70px'}}
                                                                        onClick={ (e) => handleAppendPushTarget(e, index)}>
                                                                        추가
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <Pagination
                                        pageNo={pagination.pageNo}
                                        startPageNo={pagination.startPageNo}
                                        endPageNo={pagination.endPageNo}
                                        hasPrevious={pagination.hasPrevious}
                                        hasNext={pagination.hasNext}
                                        setPageNo={setPageNo}
                                    />

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}