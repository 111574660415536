import React, {useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "config/axiosConfig";

function Edit(props) {
    const [title, setTitle] = useState('');
    const [isAutoCalc, setAutoCalc] = useState(true);
    const [isVisible, setVisible] = useState(true);
    const [sale, setSale] = useState({
        electronBillAmount: '',
        electronBill: '',
        cardAndCashAmount: '',
        cardAndCash: '',
        platformAmount: '',
        platform: '',
        onlyCashAmount: '',
        onlyCash: '',
    });
    const [purchase, setPurchase] = useState({
        electronBillAmount: '',
        electronBill: '',
        cardAndCashAmount: '',
        cardAndCash: '',
        fixedAssetAmount: '',
    });
    const [totalSaleAmount, setTotalSaleAmount] = useState(''); // 총 예상 매출
    const [totalPurchaseAmount, setTotalPurchaseAmount] = useState(''); // 총 예상 매입
    const [purchaseElectronBillItemAmount, setPurchaseElectronBillItemAmount] = useState(''); // 계산서 상품매입
    const [fictitiousPurchaseRate, setFictitiousPurchaseRate] = useState('1.960'); // 의제매입 요율
    const [fictitiousPurchaseTax, setFictitiousPurchaseTax] = useState(''); // 의제매입 세액
    const [cardTaxDiscountAmount, setCardTaxDiscountAmount] = useState(''); // 신용카드발행공제
    const [expectedVatAmount, setExpectedVatAmount] = useState(''); // 예상 부가세
    const [prevVatAmount, setPrevVatAmount] = useState(''); // 전기 부가세
    const [comments, setComments] = useState(['']);

    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search); // 쿼리 파라미터 추출
    const bizNo = queryParams.get("bizNo");
    const bizName = queryParams.get("bizName");
    const year = queryParams.get("year");
    const halfYear = queryParams.get("halfYear");
    const vatId = queryParams.get("vatId");
    const pageNo = queryParams.get("pageNo");
    const membership = queryParams.get("membership");

    const handleAutoCalc = () => {
        setAutoCalc(!isAutoCalc);
    }

    const handleVisible = () => {
        setVisible(!isVisible);
    }

    const handleInputSale = (e) => {
        const { name, value } = e.target; // e.target 에서 name 과 value 를 추출
        let amount = value.replaceAll(",", "");
        amount = amount.replace(/[^0-9]/g, "");
        let expectedAmount = name === 'platform' ? Math.trunc(amount * 2) : Math.trunc(amount / 5 * 6);
        setSale({...sale, [name]: amount, [name + 'Amount'] : expectedAmount})

        // 신용카드 발행공제 계산
        let cardAndCash = Math.trunc(sale.cardAndCashAmount);
        let platform = Math.trunc(sale.platformAmount);
        if (name === 'cardAndCash') {
            cardAndCash = Math.trunc(amount);
        }
        if (name === 'platform') {
            platform = Math.trunc(amount);
        }

        let cardTaxDiscountAmount = Math.trunc((cardAndCash + platform) * 1.1 * 13 / 1000);
        setCardTaxDiscountAmount(cardTaxDiscountAmount); //-- 신용카드 발행공제 계산 끝
    }

    const handleInputSaleAmount = (e) => {
        const { name, value } = e.target; // e.target 에서 name 과 value 를 추출
        let amount = value.replaceAll(",", "");
        amount = amount.replace(/[^0-9]/g, "");
        setSale({...sale, [name]: amount});
    }

    const handleInputPurchase = (e) => {
        const { name, value } = e.target; // e.target 에서 name 과 value 를 추출
        let amount = value.replaceAll(",", "");
        amount = amount.replace(/[^0-9]/g, "");
        let expectedAmount = Math.trunc(amount / 5 * 6);
        setPurchase({...purchase, [name]: amount, [name + 'Amount'] : expectedAmount})
    }

    const handleInputPurchaseAmount = (e) => {
        const { name, value } = e.target; // e.target 에서 name 과 value 를 추출
        let amount = value.replaceAll(",", "");
        amount = amount.replace(/[^0-9]/g, "");
        setPurchase({...purchase, [name]: amount});
    }

    const handleInputCardTaxDiscountAmount = (e) => {
        const { name, value } = e.target; // e.target 에서 name 과 value 를 추출
        let amount = value.replaceAll(",", "");
        amount = amount.replace(/[^0-9]/g, "");
        setCardTaxDiscountAmount(amount);
    }

    const handleInputPurchaseElectronBillItemAmount = (e) => { // 계산서 상품매입
        const { value } = e.target; // e.target 에서 name 과 value 를 추출
        let amount = value.replaceAll(",", "");
        amount = amount.replace(/[^0-9]/g, "");
        setPurchaseElectronBillItemAmount(amount);
    }

    const handleInputPrevVat = (e) => {
        const { value } = e.target; // e.target 에서 name 과 value 를 추출
        let amount = value.replaceAll(",", "");
        amount = amount.replace(/[^0-9]/g, "");
        setPrevVatAmount(amount);
    }

    const addComma = (price) => {
        return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const createCommentRow = () => {
        setComments([...comments, ''])
    }

    const removeCommentRow = (index) => {
        const removedComments = comments.filter((comment, offset) =>  offset !== index )
        setComments(removedComments)
    }

    const writeComment = (index, e) => {
        let newArray = [...comments];
        newArray[index] = e.target.value;
        setComments(newArray);
    }

    const saveData = () => {
        const data = {
            bizNo: bizNo,
            year: year,
            halfYear: halfYear,
            saleElectronBillAmount: sale.electronBillAmount,
            saleCreditCardAndCashReceiptAmount: sale.cardAndCashAmount,
            salePlatformAmount: sale.platformAmount,
            saleRealCashAmount: sale.onlyCashAmount,
            purchaseElectronBillAmount: purchase.electronBillAmount,
            purchaseCreditCardAndCashReceiptAmount: purchase.cardAndCashAmount,
            purchaseFixedAssetAmount: purchase.fixedAssetAmount,
            purchaseElectronBillItemAmount: purchaseElectronBillItemAmount,
            fictitiousPurchaseRate: fictitiousPurchaseRate,
            creditCardTaxDiscountAmount: cardTaxDiscountAmount,
            prevHalfYearVatAmount: prevVatAmount,
            isVisible: isVisible,
            comments: comments
        };

        if (vatId === null) {
            axios.post('/admin/biz/expected-vats', data)
                .then((res) => {
                    alert('생성 되었습니다.');
                    navigate(`/page/biz/expected/list?pageNo=${pageNo}&halfYear=${halfYear}&membership=${membership}&year=${year}`);
                });
        } else {
            axios.put(`/admin/biz/expected-vats/${vatId}`, data)
                .then((res) => {
                    alert('수정 되었습니다.');
                    navigate(`/page/biz/expected/list?pageNo=${pageNo}&halfYear=${halfYear}&membership=${membership}&year=${year}`);
                });
        }
    }

    const goList = () => {
        navigate(`/page/biz/expected/list?pageNo=${pageNo}&halfYear=${halfYear}&membership=${membership}&year=${year}`);
    };

    useEffect(() => {
        const formatedBizNo = bizNo.substring(0, 3).concat("-").concat(bizNo.substring(3, 5)).concat("-").concat(bizNo.substring(5));
        setTitle(`${year.substring(2)}년 ${halfYear === 'FIRST' ? '1' : '2'} 기 확정 예상 부가세 보고서 [ ${bizName} ${formatedBizNo} ]`);

        if (vatId !== null) {
            axios.get(`/admin/biz/expected-vats/${vatId}`)
                .then((res) => {
                    console.log(res.data);
                    setVisible(res.data.isVisible);

                    setSale({
                        electronBillAmount: res.data.saleElectronBillAmount,
                        electronBill: '',
                        cardAndCashAmount: res.data.saleCreditCardAndCashReceiptAmount,
                        cardAndCash: '',
                        platformAmount: res.data.salePlatformAmount,
                        platform: '',
                        onlyCashAmount: res.data.saleRealCashAmount,
                        onlyCash: '',
                    });

                    setPurchase({
                        electronBillAmount: res.data.purchaseElectronBillAmount,
                        electronBill: '',
                        cardAndCashAmount: res.data.purchaseCreditCardAndCashReceiptAmount,
                        cardAndCash: '',
                        fixedAssetAmount: res.data.purchaseFixedAssetAmount,
                    });

                    setPurchaseElectronBillItemAmount(res.data.purchaseElectronBillItemAmount);
                    setFictitiousPurchaseRate(res.data.fictitiousPurchaseRate);
                    setCardTaxDiscountAmount(res.data.creditCardTaxDiscountAmount);
                    // setExpectedVatAmount('');
                    setPrevVatAmount(res.data.prevHalfYearVatAmount);
                    setComments(res.data.comments);
                })
        }
    }, []);

    /// 계산서 상품매입 * 의제매입요율
    useEffect(() => {
        const fictitiousPurchaseTax = Math.trunc(Math.trunc(purchaseElectronBillItemAmount) * (parseFloat(fictitiousPurchaseRate) || 0) / 100);
        setFictitiousPurchaseTax(fictitiousPurchaseTax);
    }, [purchaseElectronBillItemAmount, fictitiousPurchaseRate]);

    /// 예상부가세 = (총 예상 매출 x 10%) - (총 예상 매입 x 10%) - 경감세액
    useEffect(() => {
        const saleElectronBillAmount = Math.trunc(sale.electronBillAmount);
        const saleCardAndCashAmount = Math.trunc(sale.cardAndCashAmount);
        const salePlatformAmount = Math.trunc(sale.platformAmount);
        const saleOnlyCashAmount = Math.trunc(sale.onlyCashAmount);
        
        const purchaseElectronBillAmount = Math.trunc(purchase.electronBillAmount);
        const purchaseCardAndCashAmount = Math.trunc(purchase.cardAndCashAmount);
        const purchaseFixedAssetAmount = Math.trunc(purchase.fixedAssetAmount);

        const totalSale = saleElectronBillAmount + saleCardAndCashAmount + salePlatformAmount + saleOnlyCashAmount;
        const totalPurchase = purchaseElectronBillAmount + purchaseCardAndCashAmount + purchaseFixedAssetAmount;
        const totalTaxDiscount = Math.trunc(cardTaxDiscountAmount) + Math.trunc(fictitiousPurchaseTax);

        setTotalSaleAmount(totalSale);
        setTotalPurchaseAmount(totalPurchase);

        const expectedVat = Math.trunc(Math.trunc(totalSale * 0.1) - Math.trunc(totalPurchase * 0.1) - totalTaxDiscount);
        setExpectedVatAmount(expectedVat);

    }, [sale, purchase, cardTaxDiscountAmount, fictitiousPurchaseTax]);

    return (
        <div className="App">
            <div className="wrapper">
                <div className="content-wrapper" style={{marginLeft: '0px'}}>

                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/page/biz/expected/list">예상 부가가치세
                                            목록</a></li>
                                        <li className="breadcrumb-item active">생성 및 수정하기</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3 style={{marginLeft: "20px"}}>{title}</h3>

                    <div className="form-group row" style={{marginLeft: "1350px"}}>
                        <div className="custom-control custom-switch" >
                            <input type="checkbox"
                                   className="custom-control-input"
                                   id="customSwitch1"
                                   checked={isAutoCalc}
                                   onChange={handleAutoCalc}
                            />
                            <label className="custom-control-label" htmlFor="customSwitch1">
                                {
                                    isAutoCalc === true
                                        ? <span>자동계산(ON)</span>
                                        : <span>자동계산(OFF)</span>
                                }
                            </label>
                        </div>

                        <div className="custom-control custom-switch custom-switch-on-success" style={{marginLeft: "10px"}}>
                            <input type="checkbox"
                                   className="custom-control-input"
                                   id="customSwitch3"
                                   checked={isVisible}
                                   onChange={handleVisible}
                            />
                            <label className="custom-control-label" htmlFor="customSwitch3">
                                {
                                    isVisible === true
                                        ? <span>노출(ON)</span>
                                        : <span>노출(OFF)</span>
                                }
                            </label>
                        </div>

                    </div>

                    <section className="content" style={{marginTop: '20px', width: '1650px'}}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="card card-danger" style={{width: "793px"}}>
                                    <div className="card-header">
                                        <h3 className="card-title">매 출 ( 총: {addComma(totalSaleAmount) || "0"} 원 )</h3>
                                    </div>
                                    <div className="card-body">
                                    <div className="row">
                                            <label htmlFor="inputEmail3"
                                                   className="col-sm-3 col-form-label">매출 세금계산서</label>
                                            <div className="col-sm-3">
                                                <input type="text" className="form-control"
                                                       placeholder="확정금액"
                                                       style={{width: "200px", textAlign: "right"}}
                                                       name="electronBillAmount"
                                                       onChange={e => {
                                                           handleInputSaleAmount(e)
                                                       }}
                                                       disabled={isAutoCalc}
                                                       value={addComma(sale.electronBillAmount) || ""}
                                                />
                                            </div>
                                            <div className="col-sm-3"
                                                 style={{marginLeft: "20px", display: isAutoCalc ? "block" : "none"}}>
                                                <input type="text" className="form-control"
                                                       style={{width: "200px", textAlign: "right"}}
                                                       placeholder="금액입력"
                                                       name="electronBill"
                                                       onChange={handleInputSale}
                                                       value={addComma(sale.electronBill) || ""}
                                                />
                                            </div>
                                        </div>

                                        <div className="row" style={{marginTop: '10px'}}>
                                            <label htmlFor="inputEmail3"
                                                   className="col-sm-3 col-form-label">매출 신용카드+현금</label>
                                            <div className="col-sm-3">
                                                <input type="text" className="form-control"
                                                       placeholder="확정금액"
                                                       style={{width: "200px", textAlign: "right"}}
                                                       name="cardAndCashAmount"
                                                       disabled={isAutoCalc}
                                                       onChange={handleInputSaleAmount}
                                                       value={addComma(sale.cardAndCashAmount) || ""}
                                                />
                                            </div>
                                            <div className="col-sm-3"
                                                 style={{marginLeft: "20px", display: isAutoCalc ? "block" : "none"}}>
                                                <input type="text" className="form-control"
                                                       style={{width: "200px", textAlign: "right"}}
                                                       placeholder="금액입력"
                                                       name="cardAndCash"
                                                       onChange={handleInputSale}
                                                       value={addComma(sale.cardAndCash) || ""}
                                                />
                                            </div>
                                        </div>

                                        <div className="row" style={{marginTop: '10px'}}>
                                            <label htmlFor="inputEmail3"
                                                   className="col-sm-3 col-form-label">매출 플랫폼</label>
                                            <div className="col-sm-3">
                                                <input type="text" className="form-control"
                                                       placeholder="확정금액"
                                                       style={{width: "200px", textAlign: "right"}}
                                                       name="platformAmount"
                                                       disabled={isAutoCalc}
                                                       onChange={handleInputSaleAmount}
                                                       value={addComma(sale.platformAmount) || ""}
                                                />
                                            </div>
                                            <div className="col-sm-3"
                                                 style={{marginLeft: "20px", display: isAutoCalc ? "block" : "none"}}>
                                                <input type="text" className="form-control"
                                                       style={{width: "200px", textAlign: "right"}}
                                                       placeholder="금액입력"
                                                       name="platform"
                                                       onChange={handleInputSale}
                                                       value={addComma(sale.platform) || ""}
                                                />
                                            </div>
                                        </div>

                                        <div className="row" style={{marginTop: '10px'}}>
                                            <label htmlFor="inputEmail3"
                                                   className="col-sm-3 col-form-label">매출 순수현금</label>
                                            <div className="col-sm-3">
                                                <input type="text" className="form-control"
                                                       style={{width: "200px", textAlign: "right"}}
                                                       placeholder="확정금액"
                                                       disabled={isAutoCalc}
                                                       name="onlyCashAmount"
                                                       onChange={handleInputSaleAmount}
                                                       value={addComma(sale.onlyCashAmount) || ""}
                                                />
                                            </div>
                                            <div className="col-sm-3"
                                                 style={{marginLeft: "20px", display: isAutoCalc ? "block" : "none"}}>
                                                <input type="text" className="form-control"
                                                       style={{width: "200px", textAlign: "right"}}
                                                       placeholder="금액입력"
                                                       name="onlyCash"
                                                       onChange={handleInputSale}
                                                       value={addComma(sale.onlyCash) || ""}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card card-primary" style={{width: "793px", marginLeft: "10px"}}>
                                    <div className="card-header">
                                        <h3 className="card-title">매 입 ( 총: {addComma(totalPurchaseAmount) || "0"} 원 )</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <label htmlFor="inputEmail3"
                                                   className="col-form-label"
                                                   style={{width: "184px", marginLeft: "8px"}}>매입 세금계산서</label>
                                            <div className="col-sm-3">
                                                <input type="text" className="form-control"
                                                       style={{width: "200px", textAlign: "right"}}
                                                       placeholder="확정금액"
                                                       disabled={isAutoCalc}
                                                       name="electronBillAmount"
                                                       onChange={handleInputPurchaseAmount}
                                                       value={addComma(purchase.electronBillAmount) || ""}
                                                />
                                            </div>
                                            <div className="col-sm-3"
                                                 style={{marginLeft: "20px", display: isAutoCalc ? "block" : "none"}}>
                                                <input type="text" className="form-control"
                                                       style={{width: "200px", textAlign: "right"}}
                                                       placeholder="금액입력"
                                                       name="electronBill"
                                                       onChange={handleInputPurchase}
                                                       value={addComma(purchase.electronBill) || ""}
                                                />
                                            </div>
                                        </div>

                                        <div className="row" style={{marginTop: '10px'}}>
                                            <label htmlFor="inputEmail3"
                                                   className="col-sm-3 col-form-label">매입 신용카드+현금</label>
                                            <div className="col-sm-3">
                                                <input type="text" className="form-control"
                                                       style={{width: "200px", textAlign: "right"}}
                                                       placeholder="확정금액"
                                                       disabled={isAutoCalc}
                                                       name="cardAndCashAmount"
                                                       onChange={handleInputPurchaseAmount}
                                                       value={addComma(purchase.cardAndCashAmount) || ""}
                                                />
                                            </div>
                                            <div className="col-sm-3"
                                                 style={{marginLeft: "20px", display: isAutoCalc ? "block" : "none"}}>
                                                <input type="text" className="form-control"
                                                       style={{width: "200px", textAlign: "right"}}
                                                       placeholder="금액입력"
                                                       name="cardAndCash"
                                                       onChange={handleInputPurchase}
                                                       value={addComma(purchase.cardAndCash) || ""}
                                                />
                                            </div>
                                        </div>

                                        <div className="row" style={{marginTop: '10px'}}>
                                            <label htmlFor="inputEmail3"
                                                   className="col-sm-3 col-form-label">매입 고정자산</label>
                                            <div className="col-sm-3">
                                                <input type="text" className="form-control"
                                                       style={{width: "200px", textAlign: "right"}}
                                                       placeholder="확정금액"
                                                       name="fixedAssetAmount"
                                                       onChange={handleInputPurchaseAmount}
                                                       value={addComma(purchase.fixedAssetAmount) || ""}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card card-secondary" style={{width: "1596px"}}>
                                <div className="card-header">
                                    <h3 className="card-title">경감 세액</h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <label htmlFor="inputEmail3"
                                               className="col-form-label"
                                               style={{width: "130px", marginLeft: "8px"}}>계산서 상품매입</label>
                                        <div className="col-sm-2">
                                            <input type="text" className="form-control"
                                                   style={{width: "200px", textAlign: "right"}}
                                                   placeholder="확정금액"
                                                   onChange={handleInputPurchaseElectronBillItemAmount}
                                                   value={addComma(purchaseElectronBillItemAmount) || ""}
                                            />
                                        </div>

                                        <label htmlFor="inputEmail3"
                                               className="col-form-label"
                                               style={{width: "130px"}}>의제매입 요율</label>

                                        <select className="form-control float-right"
                                                style={{width: '200px', marginRight: '10px'}}
                                                onChange={ (e) => setFictitiousPurchaseRate(e.target.value) }
                                                value={fictitiousPurchaseRate}>
                                            <option value="1.96">2/102</option>
                                            <option value="3.846">4/104</option>
                                            <option value="5.66">6/106</option>
                                            <option value="7.407">8/108</option>
                                            <option value="8.256">9/109</option>
                                        </select>

                                        <label htmlFor="inputEmail3"
                                               className="col-form-label"
                                               style={{width: "130px", marginLeft: "40px"}}>의제매입 세액</label>
                                        <div className="col-sm-2" style={{marginLeft: "10px"}}>
                                            <input type="text" className="form-control"
                                                   style={{width: "200px", textAlign: "right"}}
                                                   placeholder="결과값"
                                                   value={addComma(fictitiousPurchaseTax) || ""}
                                                   disabled
                                            />
                                        </div>
                                    </div>

                                    <div className="row" style={{marginTop: '10px'}}>
                                        <label htmlFor="inputEmail3"
                                               className="col-form-label"
                                               style={{width: "130px", marginLeft: "8px"}}>신용카드 발행 공제</label>
                                        <div className="col-sm-3">
                                            <input type="text" className="form-control"
                                                   style={{width: "200px", textAlign: "right"}}
                                                   placeholder="확정금액"
                                                   onChange={handleInputCardTaxDiscountAmount}
                                                   value={addComma(cardTaxDiscountAmount) || ""}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="card card-secondary" style={{width: "1596px"}}>
                                <div className="card-header">
                                    <h3 className="card-title">부가세</h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <label htmlFor="inputEmail3"
                                               className="col-form-label"
                                               style={{width: "130px", marginLeft: "8px"}}>예상 부가세</label>
                                        <div className="col-sm-2">
                                            <input type="text" className="form-control"
                                                   style={{width: "200px", textAlign: "right"}}
                                                   placeholder="확정금액"
                                                   value={addComma(expectedVatAmount) || ""}
                                                   disabled
                                            />
                                        </div>

                                        <label htmlFor="inputEmail3"
                                               className="col-form-label"
                                               style={{width: "130px"}}>전기 부가세</label>
                                        <div className="col-sm-2" style={{marginLeft: "10px"}}>
                                            <input type="text" className="form-control"
                                                   style={{width: "200px", textAlign: "right"}}
                                                   placeholder="확정금액"
                                                   onChange={handleInputPrevVat}
                                                   value={addComma(prevVatAmount) || ""}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card card-secondary" style={{width: "1596px"}}>
                                <div className="card-header">
                                    <h3 className="card-title">해설</h3>
                                    <button type="button"
                                            className="btn btn-light btn-xs"
                                            style={{width: "70px", height: "30px", marginTop: "-5px", float: "right"}}
                                            onClick={createCommentRow}>추 가
                                    </button>
                                </div>

                                <div className="card-body" id="comment-body">
                                    {
                                        comments.map((comment, index) => {
                                            return (
                                                <div className="row" style={{marginTop: '10px'}}>
                                                    <div className="col-sm-11">
                                                        <input type="text"
                                                               className="form-control"
                                                               placeholder="해설"
                                                               data-index={index}
                                                               value={comment}
                                                               onChange={e => writeComment(index, e)}/>
                                                    </div>
                                                    <button type="button" className="btn btn-block btn-danger btn-xs"
                                                            style={{width: "50px", float: "right"}}
                                                            onClick={() => removeCommentRow(index)}>삭 제
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{width: "1596px", marginTop: "40px"}}>
                            <div style={{margin: "0 auto"}}>
                                <button type="button" className="btn btn-outline-secondary"
                                        onClick={() => goList()}>
                                    취소
                                </button>
                                <button type="button" className="btn btn-outline-success"
                                        style={{marginLeft: "10px"}}
                                        onClick={saveData}>
                                    {vatId === null ? '저장' : '수정'}
                                </button>
                            </div>
                        </div>
                    </section>


                </div>
            </div>
        </div>
    );
}

export default Edit;
