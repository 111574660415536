import React from "react";
import {Route, Routes} from "react-router-dom";
import BizContract from "page/biz/contract/List";
import BizContractDetail from "page/biz/contract/Detail";
import BizExpectedVat from "page/biz/expected/List";
import BizExpectedVatEdit from "page/biz/expected/Edit";
import PushMain from "page/push/Main";

function App() {
  return (
      <div className="App">
          <Routes>
              <Route path="/page/biz/contract/list" element={<BizContract />} />
              <Route path="/page/biz/contract/detail" element={<BizContractDetail />} />
              <Route path="/page/biz/expected/list" element={<BizExpectedVat />} />
              <Route path="/page/biz/expected/edit" element={<BizExpectedVatEdit />} />
              <Route path="/page/push" element={<PushMain />} />
          </Routes>
      </div>
  );
}

export default App;
